/* eslint-disable */
const data = [
  {
    img: require('@/assets/images/products/product_aspirin@2x.jpg'),
    headline: 'Aspirin® Tablette',
    copy: 'Unser moderner Klassiker: Die Aspirin® Tablette hilft dir bei Kopfschmerzen besonders schnell das Gefühl von „Besser“ zu erleben. Dank der Weiterentwicklung, nun mit MicroAktiv-Technologie sogar doppelt so schnell, wie die frühere, klassische Aspirin® Tablette.',
    link: 'https://www.aspirin.de/produkte/aspirin-tablette/'
  },
  {
    img: require('@/assets/images/products/product_effekt@2x.jpg'),
    headline: 'Aspirin® Effect',
    copy: 'Auch wenn du nicht zuhause bist, kannst du dich bei Kopfschmerzen schnell besser fühlen. Und da du unterwegs keine Zeit für lange Texte hast – unsere Kurzform: Pulver zur praktischen Einnahme ohne Wasser. Egal wann, egal wo.',
    link: 'https://www.aspirin.de/produkte/aspirin-effect/'
  },
  {
    img: require('@/assets/images/products/product_plus-c@2x.jpg'),
    headline: 'Aspirin® Plus C',
    copy: 'In einem Glas Wasser aufgelöst lässt dich Aspirin® Plus C bei erkältungsbedingten Schmerzen und Kopfschmerzen sprudelnd schnell „Besser“ erleben. Und das mit einem Plus an Vitamin C.',
    link: 'https://www.aspirin.de/produkte/aspirin-plus-c/'
  },
  {
    img: require('@/assets/images/products/product_migraene@2x.jpg'),
    headline: 'Aspirin® Migräne',
    copy: 'Aspirin® Migräne ist dein Schmerzlinderer bei migränebedingten Kopfschmerzen – auch mit Aura. Es lindert darüber hinaus  Begleitsymptome wie Übelkeit, Licht- und Lärmempfindlichkeit.',
    link: 'https://www.aspirin.de/produkte/aspirin-migraene/'
  },
  {
    img: require('@/assets/images/products/product_complex@2x.jpg'),
    headline: 'Aspirin® Complex',
    copy: 'Nase voll von voller Nase? Aspirin® Complex befreit schnell Nase und Nebenhöhlen, stoppt Erkältungsschmerzen und senkt Fieber. So schnell wird schlecht zu „Besser“.',
    link: 'https://www.aspirin.de/produkte/aspirin-complex/'
  },
  {
    img: require('@/assets/images/products/product_coffein@2x.jpg'),
    headline: 'Aspirin® Coffein',
    copy: 'Wenn du es besonders eilig hast! Das Koffein in Aspirin® Coffein beschleunigt den Wirkeintritt der Acetylsalicylsäure. So wird dein Alltag schnell wieder besser.',
    link: 'https://www.aspirin.de/produkte/aspirin-coffein/'
  },
  {
    img: require('@/assets/images/products/product_direkt@2x.jpg'),
    headline: 'Aspirin® Direkt',
    copy: 'Aspirin® Direkt bringt dir das Gefühl von „Besser“ als praktische Kautablette. Ganz ohne Wasser. Aber ganz schön schnell.',
    link: 'https://www.aspirin.de/produkte/aspirin-direkt/'
  }
]

export default data
