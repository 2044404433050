<template>
  <section class="products  o-panel">
    <div><h1 class="u-h1 u-center">Sieben Wege zu „Besser“</h1></div>
    <div class="products__swiper">
      <swiper
        :options="swiperOption"
        ref="productSwiper"
        @slideChangeTransitionEnd="onSlideEnter"
        @slideChangeTransitionStart="onSlideLeave"
      >
        <template v-for="(product, index) in products">
          <swiper-slide :key="index">
            <img :src="product.img" :alt="product.headline" />
          </swiper-slide>
        </template>
        <div
          class="products__clickarea products__clickarea--left js-swiper-btn-prev"
          slot="button-next"
        ></div>
        <div
          class="products__clickarea products__clickarea--right js-swiper-btn-next"
          slot="button-prev"
        ></div>
      </swiper>
      <div class="products__ui-elements">
        <ArrowIcon class="products__arrow-btn products__arrow-btn--left" />
        <ArrowIcon class="products__arrow-btn products__arrow-btn--right" />
      </div>
    </div>
    <div class="products__details">
      <transition name="fade">
        <div
          class="products__detail"
          :key="currentProductIndex"
          v-if="currentProduct"
        >
          <h2 class="u-h3">{{ currentProduct.headline }}</h2>
          <p>{{ currentProduct.copy }}</p>
          <a class="u-upper" :href="currentProduct.link" target="_blank"
            >Zum Produkt</a
          >
        </div>
      </transition>
    </div>
    <div class="swiper-pagination" ref="swiperPagination"></div>
  </section>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import ArrowIcon from '@/assets/images/icon-arrow.svg?inline'
import productsData from '@/content/products'

export default {
  components: {
    swiper,
    swiperSlide,
    ArrowIcon
  },
  data() {
    return {
      products: productsData,
      currentProductIndex: 0,
      swiperOption: {
        spaceBetween: 30,
        grabCursor: true,
        slidesPerView: 5,
        centeredSlides: true,
        speed: 500,
        controlInverse: true,
        autoHeight: false,
        simulateTouch: true,
        watchOverflow: true,
        slideToClickedSlide: true,
        initialSlide: 0,
        loop: true,
        breakpoints: {
          700: {
            spaceBetween: 10,
            slidesPerView: 'auto'
          },
          1024: {
            slidesPerView: 3
          },
          1440: {
            slidesPerView: 4
          }
        },
        navigation: {
          nextEl: '.js-swiper-btn-next',
          prevEl: '.js-swiper-btn-prev'
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: false
        }
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.productSwiper.swiper
    },
    currentProduct() {
      return this.currentProductIndex === null
        ? false
        : this.products[this.currentProductIndex]
    }
  },
  methods: {
    onSlideLeave() {
      this.currentProductIndex = null
    },
    onSlideEnter() {
      this.currentProductIndex = this.swiper.realIndex
    }
  }
}
</script>

<style lang="stylus">
@require '../assets/style/1-settings/fonts'
@require '../assets/style/1-settings/colors'
@require '../assets/style/1-settings/layout'
@require '../assets/style/2-tools/responsive'

.products
  position relative
  padding 6rem 0 4rem 0
  background-image url('~@/assets/images/bg/bg-story.jpg')
  background-size cover
  background-position 50% 100%

  &__swiper
    position relative
    margin-bottom 2rem

  &__arrow-btn
    position absolute
    width 14px
    height 21px
    top 50%
    transform translate3d(-50%, -50%, 0)
    &--left
      left 39%
      +below(1440px)
        left 36%
      +below(1024px)
        left 31.5%
      +mobile()
        display none
    &--right
      transform translate3d(-50%, -50%, 0) scale(-1)
      right 38.4%
      +below(1440px)
        right 35%
      +below(1024px)
        right 30%
      +mobile()
        display none

  &__clickarea
    cursor pointer
    position absolute
    top 50%
    transform translate3d(-50%, -50%, 0)
    width 40px
    height 100%
    display block
    z-index 1
    opacity 0
    &--left
      left 39%
      +below(1440px)
        left 36.1%
      +below(1024px)
        left 31.5%
      +mobile()
        display none
    &--right
      right 36.8%
      left auto
      +below(1440px)
        right 32.6%
      +below(1024px)
        right 27.3%
      +mobile()
        display none

  &__ui-elements
    position absolute
    width 100%
    top 50%
    left 50%
    transform translate3d(-50%, -50%, 0)

  &__details
    height 200px
    padding-top 1rem
    margin-bottom 2rem

    +mobile()
      min-height 250px
      height auto

  &__detail
    text-align center
    max-width 32rem
    margin 0 auto

    +mobile()
      max-width 80%

  h1
    margin-bottom 3rem

  h2
    margin-bottom 1rem

  a
    font-family $fontSemiBold
    color $colorBrand
    font-size 15px

    &:after
      content url('../assets/images/arrow.svg')
      padding-left 5px
      width 15px
      height 12px
      display inline-block

.swiper-pagination
  width 100%
  left 50%
  transform translate3d(-50%, 0, 0)
  position relative

  &-bullet
    height 2px
    width 24px
    border-radius 2px
    background $colorBrand
    transition opacity 0.5s ease

    &:not(:first-child)
      margin-left 0.5rem

.swiper-container
  +mobile()
    responsive(
      (padding-left $siteSidePaddingMobile) \
      (padding-right $siteSidePaddingMobile),
      (padding-left $siteSidePadding) \
      (padding-right $siteSidePadding)
    )

.swiper-slide
  transition transform 0.4s ease
  +mobile()
    max-width 80%

.fade-enter-active,
.fade-leave-active
  transition all .3s ease

.fade-enter
  opacity 0

.fade-leave-to
  opacity 0
</style>
